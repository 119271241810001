var pawLibrary = {
	map: false,
	pawIsotape: false,
	selectedCat: "*",
	monthNames: ["Jan","Feb","Mar","Apr","May","June","Jul","Aug","Sept","Oct","Nov","Dec"],
	loading: "<div id=\"loader\" class=\"center\" style=\"width:100%\"><img src=\"./imgs/loader.gif\" alt=\"Loading...\"  /></div>",
	markers: [],
	offset: 0.8,
	dropdownMenus: function()
	{
		$('.parent > a').on('click', function(e) {
			var selected = $(this);
			var id = selected.attr('id');
			if(id != '') id = id.replace('showMenu','');

			if($('#subMenu' + id).length == 1)
			{
				e.preventDefault();
			}

			// Show
			if(selected.hasClass('highlight') == true) {
				// Hide
				selected.removeClass('highlight');
				$('.parent-sub').hide();
			} else {
				// Show
				selected.addClass('highlight');
				$('.parent-sub').hide();
				$('.parent a').removeClass('highlight');
				$('#subMenu' + id).fadeIn();
				$('#showMenu' + id).addClass('highlight');
			}
		});
	},
	search: function(term)
	{
		$('#searchresults-wrap').html('<div id="loading">Searching...</div>').load('ajax/search.inc.php?term=' + term);
	},
	validateForm: function()
	{
		var errors = 0;

		var name_txt = $('#name_txt');
		var email_txt = $('#email_txt');
		var message_txt = $('#message_txt');
		var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

		if(name_txt.val().length < 3)
		{
			name_txt.addClass('required_border');
			errors++;
		} else {
			name_txt.removeClass('required_border');
		}

		if( !filter.test(email_txt.val()) )
		{
			email_txt.addClass('required_border');
			errors++;
		} else {
			email_txt.removeClass('required_border');
		}

		if(message_txt.val().length < 3)
		{
			message_txt.addClass('required_border');
			errors++;
		} else {
			message_txt.removeClass('required_border');
		}

		return (errors == 0) ? true : false;

	},

	getTwitterProfilePic: function(elemId, username)
	{

		$.ajax({
			type: 'json',
		    url: '../ajax/getTwitter.inc.php?username=' + username,
		    beforeSend: function() {
		    	$('#comment' + elemId + ' .img-wrap').html('<img src="./imgs/loader.gif" alt="'+username+'" class="loader" />');
		    },
		    success: function(data){
		    	var img = data[0].image;
		    	$('#comment' + elemId + ' .img-wrap').html('<img src="'+img+'" alt="'+username+'" class="profile" />');
		    }
		});

	},

	generateMap: function(elem,lat,lng, zoomLevel) {
		var myLatlng = new google.maps.LatLng(55.669090,-4.937427);
		var myOptions = {
		  zoom: 6,
		  center: myLatlng,
      scrollwheel: false,
			draggable: true,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
		}

		this.map = new google.maps.Map(document.getElementById(elem), myOptions);
	},

	addMarker: function(lat, lng, location_title, location_add) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var contentString = '<div id="mapContent" class="infoMarker">'+
			'<p><strong>'+location_title+'</strong></p>'+
				'<p>'+location_add+'</p>'+
				'<a class="btn" href="http://maps.google.co.uk/maps?daddr='+encodeURI(location_add)+'">Get Directions</a>' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});

		var marker = new google.maps.Marker({
			position: myLatlng,
			map: this.map,
			icon: './imgs/marker.png',
			title: location_title
		});

		google.maps.event.addListener(marker, 'click', function() {
		  infowindow.open(this.map,marker);
		});

		google.maps.event.addDomListener(window, 'resize', function() {
			//var center = map.getCenter();
    	//map.setCenter(center);
		});
	},
	centerMap: function() {
		var center = this.map.getCenter();
		this.map.setCenter();
	},

	setMap : function(elem, lat, lng, location_title, location_add, drag) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var myOptions = {
		  zoom: 14,
		  center: myLatlng,
      scrollwheel: false,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		  draggable: drag
		}

		var map = new google.maps.Map(document.getElementById(elem), myOptions);

		var contentString = '<div id="mapContent">'+
			'<p><strong>'+location_title+'</strong></p>'+
				'<p>'+location_add+'</p>'+
				'<a class="btn" href="http://maps.google.co.uk/maps?daddr='+encodeURI(location_add)+'">Get Directions</a>' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});

		var marker = new google.maps.Marker({
			position: myLatlng,
			map: map,
			title: location_title,
			draggable: drag
		});

		if(drag == true) {
			google.maps.event.addListener(marker, 'dragend', function() {
					var point = marker.position;
					if(lat_txt) lat_txt.value = point.$a;
					if(long_txt) long_txt.value = point.ab;

				});
		}

		google.maps.event.addListener(marker, 'click', function() {
		  infowindow.open(map,marker);
		});

		google.maps.event.addDomListener(window, 'resize', function() {
			//var center = map.getCenter();
    	//map.setCenter(center);
		});

	},

	setMapByGeocode : function(elemId, address, zoomlevel) {

		var mapOptions = {
		  zoom: zoomlevel,
          scrollwheel: false,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		  draggable: false
		}

		var contentString = '<div id="mapContent">'+
				'<p>'+address+'</p>'+
				'<a href="http://maps.google.co.uk/maps?daddr='+encodeURI(address)+'">Get Directions</a> &raquo;' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
            content: contentString
         });

		var geocoder = new google.maps.Geocoder();
		var map = new google.maps.Map(document.getElementById(elemId), mapOptions);

		geocoder.geocode( {
			'address': address
				}, function(results, status) {
					  if (status == google.maps.GeocoderStatus.OK) {
						map.setCenter(results[0].geometry.location);
						var marker = new google.maps.Marker({
							map: map,
							position: results[0].geometry.location
						});

						google.maps.event.addListener(marker, 'click', function() {
						  infowindow.open(map,marker);
						});

					  }
					}
		);

	},

	mobileNav: function()
	{
		var mobileNavItems = $('#mobile-nav-items');
		var mobileBtn = $('#mobile-burger-btn');
		var pageBody = $('#page-wrap-inner');
		var secondMenu = mobileNavItems.find('ul.second');
		if( mobileBtn.length > 0 )
		{
			// Load Menu
			mobileBtn.click(function() {
				if(mobileBtn.hasClass('menu-open') == true) {
					pageBody.removeClass('push');
					mobileBtn.removeClass('menu-open');
				} else {
					pageBody.addClass('push');
					mobileBtn.addClass('menu-open');
				}
			});

			$('.triggerSubMenu').unbind().click(function(e) {
				var clicked = e.currentTarget.id.replace("showMenu","");
				var target = mobileNavItems.find('#subMenu' + clicked);

				if(target.hasClass('open') == false) {
					secondMenu.slideUp(function() {
						secondMenu.removeClass('open');
					});

					target.slideDown(function() {
						target.addClass('open');
					});
				} else {
					secondMenu.slideUp(function() {
						secondMenu.removeClass('open');
					});
				}
			});
		}

		$('#mobile-nav-inner-items').hcSticky({
			bottom:0
		});

	},

	onResize: function()
	{

	},

	setGallery: function()
	{
		$('#slideshow-inner').on('init', function(slick) {
			$('#rightBtn-gallery').trigger('click');
			setTimeout(function(){
				$('.loader').css('opacity', 0).css('zIndex', 0);
			}, 1600);
		}).slick({
			centerMode: true,
			nextArrow: '<span id="rightBtn-gallery" class="rightBtn-gallery"><i class="fa fa-angle-right"></i></span>',
			prevArrow: '<span id="leftBtn-gallery" class="leftBtn-gallery"><i class="fa fa-angle-left"></i></span>',
			speed: 300,
		  centerMode: true,
		  slidesToShow: 1,
		  variableWidth: true
		})

	},

	jumpToLinks: function()
	{
		$('a[href^=#]').click(function(e){
    	var name = $(this).attr('href').substr(1);
    	var pos = $('#'+name).offset();
    	$('body').animate({ scrollTop: pos.top });
    	e.preventDefault();
  	});
	},

	productGallery: function() {
		if( $('#thumbs').length > 0 ) {
			$('#gallery').on('cycle-next cycle-prev', function(e, opts) {
				$('#thumbs').cycle('goto', opts.currSlide);
			});

			$('#thumbs span.thumb-wrap').click(function(){
				var index = $('#thumbs').data('cycle.API').getSlideIndex(this);
				$('#gallery').cycle('goto', index);
			});
		}
	},
	insertParam: function(key, value, loc)
	{
		var params = false;

		if( loc == false )
		{
		    var loc = location.href;
		    	loc += '?cat_sel=&capacity_sel=&type_sel=';
		}

	    if(loc.indexOf("?") !== -1) {
	       loc = loc.split('?');
	       params = loc[1];
	       loc = loc[0];
	    }
	    loc = loc + '?';

	    if(params != false)
	    {
		    params = params.split('&');
		    var x;
		    for(x =0; x < params.length; x++)
		    {
		    	var tmp = params[x].split('=');
		    	if(tmp[0]==key) {
		    		loc += key + '=' + value + '&';
		    	} else {
		    		loc += tmp[0] + '=' + tmp[1] + '&';
		    	}
		    }
		    loc = loc.slice(0,-1);
		} else {
			loc += key + '=' + value;
		}

		return loc;
	},

	updateProductURL: function(url)
	{
		window.history.pushState(null, null, url);
	},

	isValidEmailAddress: function(emailAddress) {
    var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return pattern.test(emailAddress);
	},

	setTestimonialsItems: function() {
		$('#testimonials-about-items').slick({
			infinite: true,
			speed: 300,
			slidesToShow: 3,
			slidesToScroll: 1,
			nextArrow: '#testimonial-next',
			prevArrow: '#testimonial-prev',
			responsive: [
				{
					breakpoint: 1140,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 750,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});
	},

	filterPortfolioItems: function() {
		// init Isotope
		pawLibrary.pawIsotape = $('#portfolio-filter').isotope({
			itemSelector: '.portfolio-item',
			percentPosition: true,
			layoutMode: 'fitRows'
		});
		// bind filter button click
		var categories = $('.category-btn-wrap span');
		categories.on( 'click', function() {
			var selected = $(this);
			var filterValue = selected.attr('data-filter');
			categories.removeClass('active');
			selected.addClass('active');
			//pawLibrary.pawIsotape.isotope({ filter:'.HIDEALL' });
			pawLibrary.pawIsotape.isotope({ filter: filterValue });
			pawLibrary.selectedCat = filterValue.replace(".", "");
		});

		// mobile click
		var categories_sel = $('#filter_projects_sel');
		categories_sel.on( 'change', function() {
			var selected = $(this);
			var filterValue = selected.val();
			categories.removeClass('active');
			pawLibrary.pawIsotape.isotope({ filter: filterValue });
			pawLibrary.selectedCat = filterValue.replace(".", "");
			$('span[data-id="' + pawLibrary.selectedCat + '"').addClass('active');

		});

	},

	filterNewsItems: function() {
		// init Isotope
		pawLibrary.pawIsotape = $('#news-filter').isotope({
			itemSelector: '.item',
			percentPosition: true
		});
		// bind filter button click
		var categories = $('.category-btn-wrap span');
		categories.on( 'click', function() {
			var selected = $(this);
			var filterValue = selected.attr('data-filter');
			categories.removeClass('active');
			selected.addClass('active');
			pawLibrary.pawIsotape.isotope({ filter: filterValue });
			pawLibrary.selectedCat = filterValue.replace(".", "");
		});

		// mobile click
		var categories_sel = $('#filter_news_sel');
		categories_sel.on( 'change', function() {
			var selected = $(this);
			var filterValue = selected.val();
			categories.removeClass('active');
			pawLibrary.pawIsotape.isotope({ filter: filterValue });
			pawLibrary.selectedCat = filterValue.replace(".", "");
			$('span[data-id="' + pawLibrary.selectedCat + '"').addClass('active');

		});

	},

	portfolioDialog: function() {

		// DIALOG BOX
		var selected = {};
		$('a.portfolio-item').fancybox({
			arrows : false,
			toolbar : false,
			infobar : false,
			hash: false,
			beforeShow : function( instance, current ) {
				var index = current.index;
				var item = $('a.portfolio-item');
						item = $(item[index]);

				var selected = {
					title: item.find('.img-wrap img').attr('alt'),
					img: item.find('.img-wrap img').attr('src'),
					content: item.find('.content').html(),
					website: item.find('.website').html(),
					number: parseInt(item.attr('data-number'))
				}
				if(selected.content == "") {
					selected.content = item.find('.txt-wrap').html();
				}

				$('#item-title').html(selected.title);
				$('#item-img div').html('<img src="' + selected.img + '" alt="' + selected.title + '" />');
				$('#item-desc').html(selected.content);
				$('#item-btn').attr("href", selected.website);

				$('#control-right-btn').unbind().click(function() {
					if(pawLibrary.selectedCat == "*") {
						instance.next(1000);
					} else {
						var selectedCat = pawLibrary.selectedCat;
						var x = selected.number + 1;
						if(x > (instance.group.length - 1)) {
							x = 0;
						}

						var items = $('a.portfolio-item');
						var item = $(items[x]);

						while(item.hasClass(pawLibrary.selectedCat) == false) {
							x = x + 1;
							if(x > (instance.group.length - 1)) {
								x = 0;
							}
							item = $(items[x]);
						}

						instance.jumpTo(x, 1000);
					}

				});
				$('#control-left-btn').unbind().click(function() {
					if(pawLibrary.selectedCat == "*") {
						instance.previous(1000);
					} else {
						var selectedCat = pawLibrary.selectedCat;
						var x = selected.number - 1;
						if(x <= 0) {
							x = instance.group.length - 1;
						}

						var items = $('a.portfolio-item');
						var item = $(items[x]);

						while(item.hasClass(pawLibrary.selectedCat) == false) {
							x = x - 1;
							if(x <= 0) {
								x = instance.group.length - 1;
							}
							item = $(items[x]);
						}

						instance.jumpTo(x, 1000);
					}



				});
			}
		});

	},

	teamDialog: function() {
		// DIALOG BOX
		var selected = {};
		$('#team-items .meet-the-team-items > a.item').fancybox({
			arrows : false,
			toolbar : false,
			infobar : false,
			hash: false,
			beforeShow : function( instance, current ) {
				var index = current.index;
				var item = $('#team-items .meet-the-team-items > a.item');
						item = $(item[index]);
				var selected = {
					title: item.find('.img-wrap img').attr('alt'),
					img: item.find('.img-wrap img').attr('src'),
					content: item.find('.content').html(),
					email: item.find('.email').html(),
					linkedin: item.find('.linkedin').html(),
					twitter_link: item.find('.twitter_link').html(),
				}

				selected.img = selected.img.replace("thumb-", "");

				$('#item-title').html(selected.title);
				$('#item-img').attr('style', 'background-image:url(' + selected.img + ')');
				$('#item-img div').html('<img src="' + selected.img + '" alt="' + selected.title + '" />');
				$('#item-desc').html(selected.content);
				$('#linkedin-btn').attr('href', selected.linkedin);
				$('#email-btn').attr('href', 'mailto:' + selected.email).html(selected.email);
				$('#twitter-btn').attr('href', selected.twitter_link).html("contact us"); 
				console.log
				$('#control-right-btn').unbind().click(function() {
					instance.next();
				});
				$('#control-left-btn').unbind().click(function() {
					instance.previous();
				});

			}
		});
	},

	progressCircles: function() {
		// SET THE CIRCLES
		var circles = $('.progress-circle');
		$.each(circles, function(key,row) {
					var row = $(row);
					var target = row.attr('data-target');
					row.circleProgress({
						value: 0,
						size: 127,
						startAngle: 275,
						fill: '#21252F',
						emptyFill: '#ccc',
						thickness: 7,
					}).on('circle-animation-progress', function(event, progress, stepValue) {

						var number = target.match(/(\d+)?([.]?\d{0,2})?/g);
								number = number.join("");
								number = number.split(".");
						var decimal = (number.length > 0) ? number[1] : "";
								number = number[0];

						var tmp = target.split(number);
						var prefix = tmp[0];
						var postfix = (tmp.length > 1) ? tmp[1] : "";

						if(postfix != "") {
							postfix = postfix.split(".");
							postfix = postfix[0];
						}

						var value = number * stepValue;
						var number_elem = $(this).find('strong');

						if((value.toFixed(0) > 0) && (decimal != null)) {
							postfix = "." + decimal + postfix; // wee hack to factor decimal places
						}

						number_elem.text(prefix + value.toFixed(0) + postfix);
					}).on('circle-animation-start', function(event) {
						row.removeClass('start');
					}).on('circle-animation-end', function(event) {
							if(row.hasClass('start') == false) {
								if(key == 0) {
									var sep = $('#progress-circles .sep');
									$(sep[0]).addClass('selected');
								}
								if(key == 1) {
									var sep = $('#progress-circles .sep');
									$(sep[1]).addClass('selected');
								}
							}
					});

					var width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
					var delay = (width > 600) ? 2500 : 1500;

					new Waypoint({
						element: document.getElementById('figures-details'),
						handler: function(direction) {
							setTimeout(function() {
								row.circleProgress('value', 1);
							}, delay * key);
						},
						offset: "40%"
					});

		});
	},

	setChart: function() {
		// SVG LINE CHART
		/*var plot = [
			[2, 2, 6, 2, 4, 2, 5, 8, 10],
			[0.5, -0.5, 2, 7, 0, 4, 1, 5, 7]
		];*/

		var plot = [
			[2, 6, 3.5, 7.5, 11, 6, 13.5, 20],
			[4, 2, 7, 3, 7, 12, 10, 18]
		];

    var chart = new Chartist.Line('.ct-chart', {
      labels: [],
      series: plot,
      }, {
        height: 320,
        fullWidth: true,
        showLabel: false,
        lineSmooth: Chartist.Interpolation.cardinal({
          tension: 0.2
        }),
        axisY: {
          onlyInteger: true,
          offset: 0
        },
        onCreated: function() {

        }
			});

			chart.on('draw', function(data) {
				if (data.type === 'point') {
					var circle = new Chartist.Svg('circle', {
						cx: [data.x],
						cy: [data.y],
						r: [4]
					}, 'ct-circle');
					data.element.replace(circle);
				}
			});
	},

	homeSlider: function() {

		$('#home-portfolio-items').slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			infinite: true,
			arrows: false,
			centerMode: true,
			responsive: [
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1
					}
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			]
		});
	},

	isExternal: function(url) {
    var match = url.match(/^([^:\/?#]+:)?(?:\/\/([^\/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);
    if (typeof match[1] === "string" && match[1].length > 0 && match[1].toLowerCase() !== location.protocol) return true;
    if (typeof match[2] === "string" && match[2].length > 0 && match[2].replace(new RegExp(":("+{"http:":80,"https:":443}[location.protocol]+")?$"), "") !== location.host) return true;
    return false;
	},

	init: function()
	{
		pawLibrary.mobileNav();
		//pawLibrary.banners();
		//pawLibrary.jumpToLinks();
		pawLibrary.dropdownMenus();
		pawLibrary.setGallery();

		if($('#techstart-graph').length > 0) {
			pawLibrary.setChart();
		}

		if( $('#home-portfolio-items').length > 0 ) {
			pawLibrary.homeSlider();
		}

		if( $('#navigate-triangle').length > 0 ) {
			$('#navigate-triangle .triangle-shape, #navigate-triangle .triangle-lbl').click(function() {
				$('#navigate-triangle .triangle-lbl').removeClass('active');
				var selected = $(this).attr('id');
				var stats_number = $(this).attr('data-stats-number');
				if(selected.indexOf("-lbl") == -1) {
					selected += '-lbl';
				}
				if($('#' + selected).length > 0) {
					$('#' + selected).addClass('active');
				}
				// GET STATS
				if($('#pyramid_stats_' + stats_number).length == 1) {
					$('#pyramid_stats_col .col-items-wrap').addClass('hidden');
					$('#pyramid_stats_' + stats_number).removeClass('hidden');
				}
			});
		}

		if($('#team-items').length > 0) {
			pawLibrary.teamDialog();
		}

		// CIRCLES
		if($('#progress-circles').length > 0) {
			pawLibrary.progressCircles();
		}

		// FLIP CARDS
		if($('.card').length > 0) {
			$(".card").flip({
  			trigger: 'hover'
			});
		}

		// MORE DETAILS
		if($('#more').length > 0) {
			pawLibrary.portfolioDialog();
		}

	}

}

$(window).load(function() {
	pawLibrary.init();

	if($('#portfolio-filter').length > 0) {
		pawLibrary.filterPortfolioItems();
	}

	if($('#news-filter').length > 0) {
		pawLibrary.filterNewsItems();
	}
});

$(window).ready(function() {


});


$(window).smartresize(pawLibrary.pawIsotape);
